"use client";

import React, { ReactNode } from "react";

interface WrapperContainerProps {
  children: ReactNode;
}

const WrapperContainer = ({ children }: WrapperContainerProps) => {
  return <div className={`max-w-[1340px] m-auto w-full`}>{children}</div>;
};

export default WrapperContainer;
