"use client";
import styles from "./featuredcarouselstyles.module.scss";

import React, { useMemo } from "react";
import WrapperContainer from "@/modules/common/components/max-width-wrapper";
import ProductCard from "@/modules/common/components/product-card";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useCustomer from "@/lib/hooks/useCustomer";

var settings = {
  nextArrow: (
    <span className="aright">
      <img src="/images/arrow-left-02.svg" />
    </span>
  ),
  prevArrow: (
    <span className="aleft">
      <img src="/images/arrow-left-02.svg" />
    </span>
  ),
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  swipeToSlide: true,
  pauseOnHover: false,
};

const FeaturedCarousel = ({ featuredProducts }) => {
  const { customerId } = useCustomer();

  const memoizedProducts = useMemo(() => {
    return featuredProducts.map((product, index) => {
      if (product?.id != null) {
        return (
          <div key={index}>
            <ProductCard
              product={product}
              index={index}
              home={true}
              customerId={customerId}
            />
          </div>
        );
      }
    });
  }, [featuredProducts]);
  return (
    <div className={`w-full px-5 sm:px-14 py-14 p-8 sm:pt-12 sm:pb-20`}>
      <div className="block sm:flex flex-row justify-between items-center mb-6 sm:mb-14 ">
        <div className={`max-w-[1340px] m-auto w-full`}>
          <h2
            className="w-full text-[2.5rem] sm:text-5xl"
            style={{ maxWidth: "100%" }}
          >
            Featured Products
          </h2>
          {/* <SliderControls wrapperRef={wrapperRef} /> */}
        </div>
      </div>
      <div className={` ${styles.product_listing_container} w-full  pb-14`}>
        <WrapperContainer>
          <div className="grid grid-cols-1">
            <Slider {...settings}>{memoizedProducts}</Slider>
          </div>
        </WrapperContainer>
      </div>
    </div>
  );
};

export default FeaturedCarousel;
