// "use client";
import Link from "next/link";
import CartWidget from "@/modules/licensee/shop/cart-widget";
import { useState } from "react";
import CartIcon2 from "@/modules/common/icons/cart-icon-2";
import { BaseCart } from "@medusajs/types/dist/http/cart/common";

interface HeaderCartProps {
  quantity: number;
  cart: BaseCart;
  isVisible: boolean;
  updateLineItem: (lineItemId: string, quantity: number) => Promise<void>;
}

export default function HeaderCart({
  quantity,
  cart,
  isVisible,
  updateLineItem,
}: HeaderCartProps) {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <>
      <div className="header_cart_icon fixed top-0 mt-[1.5rem] right-[5.5rem] z-[100]">
        <div onMouseLeave={() => setIsHovered(false)}>
          <div
            className="flex flex-row items-center"
            onMouseEnter={() => setIsHovered(true)}
          >
            <Link href={"/licensee/cart"} className="h-[70px] z-[100]">
              <CartIcon2 />
              <div
                className="header_cart_quantity relative -top-[30px] left-[42px] flex justify-center items-center rounded-full bg-[#fff]"
                style={{ height: "36px", width: "36px" }}
              >
                <p className="text-center font-sans text-sm font-bold color-black">
                  {quantity}
                </p>
              </div>
            </Link>
          </div>
          <CartWidget
            cart={cart}
            isVisible={isVisible}
            isHovered={isHovered}
            updateLineItem={updateLineItem}
          />
        </div>
      </div>
    </>
  );
}
